<template>
  <div class="purchase-body">
    <h2 class="marketplace-title">Marketplace</h2>
    <div class="grid-wrapper">
      <SaleStatus :key="version" />

      <div class="shop-wrapper">
        <div class="offers-wrapper card main-cards">
          <h4 class="offers-title">Tortuga Marketplace</h4>
          <div class="w-layout-grid offers-list">
            <div
              v-for="pack in PEARL_PACK_DATA"
              :key="pack.name"
              class="offer card pd-48px---32px shop-item w-inline-block"
            >
              <BuyPackButton
                :price="pack.price"
                :pearl-reward="pack.pearlReward"
                :pearl-black-reward="pack.pearlBlackReward"
                :name="pack.name"
                :image-path="pack.imagePath"
                @success="updateData"
              />
            </div>
          </div>
          <p class="text-block">
            When purchasing Pearl coins on the Tortuga Marketplace, they are automatically locked and locked in a gaming
            smart contract for one year, followed by a linear monthly unlocking schedule of 5%. Buying pearls on the
            trading platform provides players with the automatic opportunity to participate in the daily distribution of
            all pearls harvested from the sea bed.
          </p>
          <p class="text-block">
            After utilizing the opportunity for full coin unlocking, the player no longer participates in distributing
            harvested pearls. Once all the harvested pearls are distributed, a mechanism for burning circulating coins
            will be initiated by creating NFTs. The burning process will reduce the number of coins, and their demand
            will increase as the Pearl coin will play a key role in both current and subsequent game scenarios, being
            the primary mechanism for transaction payments.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useHeadSafe } from '@unhead/vue';
import { ref } from 'vue';
import { PEARL_PACK_DATA } from '~/utils/constants/marketplace';

useHeadSafe({
  title: 'Pearl Diver Island - Marketplace'
});
const version = ref(0);

function updateData() {
  version.value = version.value++;
}
</script>

<style scoped lang="scss">
.purchase-body {
  display: flex;
  justify-content: center;
  gap: 37px;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: 60px;

  @media screen and (max-width: 900px) {
    gap: 0;
  }

  @media screen and (max-width: 767px) {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
}

.marketplace-title {
  color: #fff;
  font-size: 64px;
  margin: 0;
  margin-bottom: 3px;

  @media screen and (max-width: 990px) {
    margin-top: 35px;
    margin-bottom: 35px;
  }
}

.offers-list {
  margin-bottom: 48px;
}

.card {
  overflow: hidden;
  padding: 6px 44px;
}
.w-layout-grid {
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-columns: 1fr;
  display: grid;
}

.offers-title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
  color: var(--main-text-color);
  font-weight: 700;
  font-family: Grenze, sans-serif;
}

.offers-wrapper {
  border-radius: 12px;
  overflow: hidden;
  box-sizing: border-box;
  border: 1px solid var(--blue-2);
  background: var(--blue-3);
  margin-bottom: 20px;
}

.sale-wrapper,
.shop-wrapper {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

.offer {
  display: flex;
  align-items: baseline;
  transition: ease all 200ms;
  cursor: pointer;
}

.card.pd-48px---32px {
  padding: 53px 33px;
  padding-top: 53px;
  padding-bottom: 53px;
}

.card.pd-48px---32px.shop-item {
  color: rgba(255, 255, 255, 0.5);
  background-color: #021b26;
  background-image: radial-gradient(circle closest-corner at 50% 0, #3b7285, rgba(0, 0, 0, 0));
  padding-top: 20px;
  padding-bottom: 20px;
  text-decoration: none;
  transition:
    all 0.3s,
    color 0.3s;

  &:hover {
    background-image: radial-gradient(circle closest-corner at 50% 0, #3faa58, rgba(0, 0, 0, 0));
  }
}

@media screen and (max-width: 991px) {
  .card.pd-48px---32px.shop-item {
    background-image: radial-gradient(circle farthest-side at 50% 0, #3b7285, rgba(0, 0, 0, 0) 50%);
    flex-direction: column;
    align-items: stretch;
    padding: 16px 20px;
    display: flex;
  }

  .w-layout-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 767px) {
  .card.pd-48px---32px.shop-item {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media screen and (max-width: 479px) {
  .card.pd-48px---32px.shop-item {
    padding: 12px 8px;
    font-size: 12px;
  }

  .w-layout-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

.grid-wrapper {
  grid-column-gap: 28px;
  grid-row-gap: 28px;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  display: grid;
  align-items: flex-start;
}

@media screen and (max-width: 991px) {
  .grid-wrapper {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 767px) {
  .grid-wrapper {
    flex-direction: column;
    display: flex;
  }
}
</style>
