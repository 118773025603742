export const PEARL_PACK_DATA = [
  {
    price: 10,
    pearlReward: 1000,
    pearlBlackReward: 0,
    name: 'Mini handful of Pearls',
    imagePath: 1000
  },
  {
    price: 50,
    pearlReward: 5000,
    pearlBlackReward: 0,
    name: 'Standard handful of Pearls',
    imagePath: 5000
  },
  {
    price: 100,
    pearlReward: 10000,
    pearlBlackReward: 0,
    name: 'Handful of Pearls',
    imagePath: 10000
  },
  {
    price: 250,
    pearlReward: 25000,
    pearlBlackReward: 0,
    name: 'Extra handful of Pearls',
    imagePath: 25000
  },
  {
    price: 500,
    pearlReward: 50000,
    pearlBlackReward: 0,
    name: 'Shell Full of Pearls',
    imagePath: 50000
  },
  {
    price: 1000,
    pearlReward: 100000,
    pearlBlackReward: 500,
    name: 'Pouch Full of Pearls',
    imagePath: 100000
  },
  {
    price: 2500,
    pearlReward: 250000,
    pearlBlackReward: 1625,
    name: 'Bag Full of Pearls',
    imagePath: 250000
  },
  {
    price: 5000,
    pearlReward: 500000,
    pearlBlackReward: 3500,
    name: 'Extra bag Full of Pearls',
    imagePath: 500000
  },
  {
    price: 10000,
    pearlReward: 1000000,
    pearlBlackReward: 8500,
    name: 'Small Plate of Pearls',
    imagePath: 1000000
  },
  {
    price: 15000,
    pearlReward: 1500000,
    pearlBlackReward: 14000,
    name: 'Plate Full of Pearls',
    imagePath: 1500000
  },
  {
    price: 20000,
    pearlReward: 2000000,
    pearlBlackReward: 19500,
    name: 'Big Plate Full of Pearls',
    imagePath: 2000000
  },

  {
    price: 25000,
    pearlReward: 2500000,
    pearlBlackReward: 25000,
    name: 'Tank Full of Pearls',
    imagePath: 2500000
  }
];
