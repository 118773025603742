<template>
  <div class="sale-wrapper">
    <div class="card main-cards">
      <h4 class="sale-title">Total Token Availability</h4>

      <div class="token-supply-wrapper">
        <div class="token-info-wrapper">
          <div class="text-white">For Sale</div>
          <div class="text-numbers">
            {{ format(saleAmountRef) }}
          </div>
        </div>
        <div class="token-progress-wrapper">
          <div class="progress-bar-bg">
            <div class="progress-bar-full" :style="`width: ${percentLeft}%`" />
            <div class="progress-bar-text-wrapper">
              <div>{{ percentLeft }}%</div>
            </div>
          </div>
        </div>
        <div class="token-info-wrapper last">
          <div class="text-white">Acquired</div>
          <div class="text-numbers">
            {{ format(sold) }}
          </div>
        </div>
      </div>
      <div class="btns-wrapper">
        <button class="btn-outline" @click="toggleModal(ModalTypes.AVAILABILITY)">About Token Availability</button>
      </div>
    </div>

    <el-dialog
      v-if="isInfoModalOpen.availability"
      v-model="isInfoModalOpen.availability"
      class="info"
      :fullscreen="$device.isMobile"
      title="Total Token Availability"
      :append-to-body="true"
    >
      <p class="text-block">
        This indicator shows the remaining Pearl tokens on the Tortuga trading platform. The sale of tokens sponsors
        hiring professional divers to retrieve the remaining scattered Pearl tokens from the sea bed. Only pearls
        obtained on this trading platform give players the right to automatically participate in the daily distribution
        of pearls extracted from the sea bed, proportional to each player's current market share of Pearl. All purchased
        pearls are automatically locked and locked by a gaming smart contract for one year, after which a linear monthly
        unlocking schedule of 5% follows.
      </p>
      <p>
        Captain Peg-Leg Pete, who organizes the pearl extraction operation, is ready to share all the pearls harvested
        from the sea bed with the initial buyers. It seems that those players who are smart and brave enough to support
        their endeavor quickly may reap significant benefits by doubling their token amount.
      </p>

      <div class="btns-wrapper">
        <button class="btn-primary" size="large" @click="isInfoModalOpen.availability = false">OK</button>
      </div>
    </el-dialog>

    <div class="card main-cards">
      <div class="max-token-supply-wrapper">
        <h4 class="sale-title">Community share</h4>
        <div class="max-total-supply pearl-wrapper">
          <img src="/img/tokens/pearl.svg" loading="lazy" width="48" />
          <p class="total-supply">
            <!-- {{ format(maxTotalSupply) }} -->
            {{ format(2000000000) }}
          </p>
        </div>
        <div class="btns-wrapper">
          <button class="btn-outline" @click="toggleModal(ModalTypes.COMMUNITY)">About Community Share</button>
          <a class="btn-primary" :href="link" target="_blank">Check On Explorer</a>
        </div>
      </div>
    </div>
    <el-dialog
      v-if="isInfoModalOpen.community"
      v-model="isInfoModalOpen.community"
      class="info"
      :fullscreen="$device.isMobile"
      title="About Community Share"
      :append-to-body="true"
    >
      <p class="text-block">
        Community share is the actual total amount of originally minted {{ format(2000000000) }} Pearl tokens available
        on the blockchain. While not all of them will be actively circulating on the market right now – once all Pearls
        are bought from Tortuga Marketplace and retrieved from the seabed, there will be no option to issue more. On the
        contrary, the
        <b>total Pearl supply will shrink over time, as tokens will be burned in the NFT creation process</b>,
        permanently removing them from circulation.
      </p>

      <div class="btns-wrapper">
        <button class="btn-primary" size="large" @click="isInfoModalOpen.community = false">OK</button>
      </div>
    </el-dialog>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, computed } from 'vue';
import { BigNumber } from 'bignumber.js';
import useEnvs from '~/composables/useEnvs';
import { useTokensReader } from '#imports';
import { useMainStore } from '~/stores/mainStore';

enum ModalTypes {
  AVAILABILITY = 'availability',
  COMMUNITY = 'community'
}

const { saleAmount, tokenMaxTotalSupply } = useTokensReader();

onMounted(async () => {
  [saleAmountRef.value, maxTotalSupplyRef.value] = await Promise.all([saleAmount(), tokenMaxTotalSupply()]);
});
const saleAmountRef = ref('...');
const maxTotalSupplyRef = ref('...');
const isInfoModalOpen = ref<Record<ModalTypes, boolean>>({
  availability: false,
  community: false
});
const { blockchain } = useEnvs();
const store = useMainStore();
const total = blockchain.contracts[store.currentToken].purchase;
const link = blockchain.blockScanUrl + '/token/' + blockchain.contracts.pearl.addresses.contract;

const sold = computed(() => {
  if (saleAmountRef.value === '...') return '...';
  return new BigNumber(total).minus(new BigNumber(saleAmountRef.value)).toString();
});

const percent = computed(() => {
  if (saleAmountRef.value === '...') return '...';
  return new BigNumber(sold.value).dividedBy(new BigNumber(total)).multipliedBy(100).toFixed(1);
});

const percentLeft = computed(() => {
  if (percent.value === '...') return '...';
  return new BigNumber(100).minus(new BigNumber(percent.value));
});

function format(value: string | number) {
  if (!value || value === '...') {
    return value;
  }
  return new BigNumber(+value).toFormat();
}

const toggleModal = (key: ModalTypes) => {
  isInfoModalOpen.value[key] = !isInfoModalOpen.value[key];
};
</script>

<style scoped lang="scss">
a.line-btn {
  border-radius: 86px;
  border: 1px solid var(--dark-4);
  font-size: 24px;
  text-transform: capitalize;
  padding: 12px 38px;
  margin: auto;
  color: var(--main-text-color);
  display: inline-flex;
  text-decoration: none;
}

.max-total-supply {
  justify-content: center;
  align-items: center;

  padding-bottom: 30px;
  display: flex;
}

.btns-wrapper {
  margin-top: 0;
}

@media screen and (max-width: 767px) {
  .pearl-wrapper {
    padding-top: 8px;
    padding-bottom: 16px;
  }
}

@media screen and (max-width: 479px) {
  .pearl-wrapper {
    padding-top: 0;
  }
}

.card {
  border: 1px solid var(--blue-2);
  background: var(--blue-3);
  border-radius: 12px;
  transform: translate(0);
}

.card.main-cards {
  margin-bottom: 0;
  padding: 20px 33px 33px;
}

@media screen and (max-width: 991px) {
  .card.main-cards {
    margin-bottom: 16px;
    padding: 20px;
    font-size: 16px;
    line-height: 1.63em;
  }
}

@media screen and (max-width: 767px) {
  .card.main-cards {
    padding: 16px;
    font-size: 14px;
  }
}

.sale-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 22px;
  width: 100%;
}

@media screen and (max-width: 991px) {
  .pearl-wrapper {
    padding-top: 16px;
    padding-bottom: 24px;
  }

  .sale-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}

.pb-wrapper {
  position: relative;
  height: 20px;
  border-radius: 40px;
  overflow: hidden;
  width: 100%;
  margin: 12px 0px;
}

.total-supply {
  color: #fff3e4;
  margin-bottom: 0;
  padding-left: 8px;
  font-family: Eczar, sans-serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 100%;
}

@media screen and (max-width: 991px) {
  .total-supply {
    font-size: 24px;
  }
}

.token-supply-wrapper {
  justify-content: space-between;
  display: flex;
  margin-bottom: 30px;
}

.token-info-wrapper {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 0;
  padding-right: 16px;
  line-height: 120%;
  display: flex;
}

.token-progress-wrapper {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.progress-bar-bg {
  width: 100%;
  height: 40px;
  background-image: linear-gradient(#17353f, #000);
  border: 1px solid #3b7285;
  border-radius: 12px;
  position: relative;
  overflow: hidden;
}

.progress-bar-full {
  background-image: radial-gradient(circle at 100%, #60ff7b, #00bfff);
  border-right: 1px solid #8cfd9f;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.progress-bar-text-wrapper {
  justify-content: center;
  align-items: center;
  font-weight: 800;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  font-family: Grenze, sans-serif;
  color: var(--main-text-color);
}

.token-info-wrapper.last {
  padding-left: 16px;
  padding-right: 0;
}

.text-white {
  color: var(--main-text-color);
  font-family: Grenze, sans-serif;
}

.text-numbers {
  font-family: Grenze, sans-serif;
}

@media screen and (max-width: 991px) {
  .token-info-wrapper {
    align-items: flex-start;
    padding-right: 8px;
    font-size: 14px;
  }

  .token-info-wrapper.last {
    align-items: flex-end;
    padding-left: 8px;
  }

  .token-supply-wrapper {
    flex-wrap: wrap;
    margin-top: 12px;
  }

  .token-progress-wrapper {
    width: 100%;
    flex: 1 0 auto;
    order: -1;
    margin-bottom: 8px;
  }
}
</style>
